<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <searchingBar :components="['filter']" @Fectch="FetchTable" @filterValue="filterValue = $event"/>
    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button @click="OpenModal('store')" pill  variant="primary" >
          <b-icon-plus/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="ruleForm"
          :model="formObj.form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="code" label="Clave del Almacén" prop="code" >
            <a-input v-model.trim="formObj.form.code"/>
          </a-form-model-item>
          <a-form-model-item ref="name" label="Nombre del Almacén" prop="name" >
            <a-input v-model.trim="formObj.form.name"/>
          </a-form-model-item>
          <a-form-model-item ref="work_center_id" label="Planta" prop="work_center_id" >
            <a-select v-model="formObj.form.work_center_id"
              placeholder="Seleccionar Planta"
              show-search allowClear
            >
              <a-select-option  v-for="option in work_centers" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="details" label="Detalles del Almacén" prop="details" >
            <a-textarea placeholder="Ingresar los detalles de almacén" :rows="4" v-model.trim="formObj.form.details"/>
          </a-form-model-item>
          <div v-show="flag">
            <a-form-model-item label="Número de niveles:"
                                        class="mx-1"
                                        ref="wlevels"
                                        prop="wlevels"
            >
              <a-input-number v-model="formObj.form.wlevels"
                                style="width: 100%"
                                :min="0"
                                :max="1000"
                                :step="1"
                />
            </a-form-model-item>
            <a-form-model-item label="Número de filas:"
                                          class="mx-1"
                                          ref="wposition_rows"
                                          prop="wposition_rows"
            >
              <a-input-number v-model="formObj.form.wposition_rows"
                                style="width: 100%"
                                :min="0"
                                :max="1000"
                                :step="1"
                />
            </a-form-model-item>
            <a-form-model-item label="Número de colummnas:"
                                          class="mx-1"
                                          ref="wposition_columns"
                                          prop="wposition_columns"
            >
              <a-input-number v-model="formObj.form.wposition_columns"
                                style="width: 100%"
                                :min="0"
                                :max="1000"
                                :step="1"
                />
            </a-form-model-item>
          </div>
          <div class="row justify-content-center" v-show="!load">
            <responsive-button variant="primary" class="mx-2" pill  @ClickEvent="onSubmit" size="sm" responsive="md" icon="archive-fill" text="Guardar"/>
            <responsive-button variant="danger" class="mx-2" pill  @ClickEvent="onDelete" size="sm" responsive="md" icon="trash" text="Eliminar" v-show="!flag"/>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal" />
      </template>
    </b-modal>
    <!-- Tabla -->
    <detail-catalog-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="FetchTable"
                          @searchInput="searchInput = $event"
    >
      <div slot="details" slot-scope="record">
        <small><strong><b-icon icon="building"></b-icon> Centro de Trabajo: </strong>{{record.record.work_centers.sap_code}} - {{record.record.work_centers.name}}<br></small>
      </div>
    </detail-catalog-table>
    <!-- Tabla -->
  </div>
</template>

<script>
import DetailCatalogTable from './catalogs/table.vue'
import { notification, Modal } from 'ant-design-vue'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'whCatalogs',
  components: {
    DetailCatalogTable,
  },
  mixins: [fractalMixin],
  data() {
    return {
      // <!-- Constantes -->
      flag: false,
      resource: 'warehouses',
      resourceName: 'Almacenes',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
          name: 'warehouse_menu',
        },
        {
          breadcrumbName: 'Gestión de Almacenes',
          aIcon: '',
        },
      ],
      searchInput: null,
      formObj: {
        resourceType: 'warehouses',
        form: {
          id: '0',
          name: '',
          details: '',
          work_center_id: undefined,
          code: '',
          wlevels: undefined,
          wposition_rows: undefined,
          wposition_columns: undefined,
        },
      },
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Clave de Almacén',
            dataIndex: 'code',
            key: 'code',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
          },
          {
            title: 'Nombre de Almacén',
            dataIndex: 'name',
            key: 'name',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
          },
          {
            title: 'Descripción',
            dataIndex: 'detail',
            key: 'detail',
            class: 'text-center',
            scopedSlots: {
              customRender: 'myCustomColumns',
            },
          },
          {
            title: 'Detalles de Almacén',
            dataIndex: 'details',
            key: 'details',
            class: 'text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            class: 'text-center',
            key: 'x',
            width: '9%',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      filterValue: '',
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->

      // <!-- Constantes -->
      // <!-- Form with Model -->
      // Relaciones
      relationships: {
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
      },
      // arrays selects
      work_centers: [],
      // Reglas de validación
      rules: {
        work_center_id: [
          { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
        ],
        name: [
          { required: true, message: 'El campo Nombre de Almacén es obligatorio.', trigger: 'blur' },
        ],
        code: [
          { required: true, message: 'El campo Clave del Almacén es obligatorio.', trigger: 'blur' },
        ],
        details: [
          { required: true, message: 'El campo Detalles del Almacén es obligatorio.', trigger: 'blur' },
        ],
        wlevels: [
          { required: true, message: 'El campo Número de Niveles es obligatorio.', trigger: 'blur' },
        ],
        wposition_rows: [
          { required: true, message: 'El campo Número de Renglones es obligatorio.', trigger: 'blur' },
        ],
        wposition_columns: [
          { required: true, message: 'El campo Número de Columnas es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
    }
  },
  methods: {
    // <!-- Get,Paginate,Search & Filter Data  -->
    async FetchTable(paginationEvent = {}) {
      const params = {}
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(paginationEvent, 'warehouses', this.tableObject, params).then(
        () => {
        },
      )
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
      ]
      const [workCenters] = await this.GetConcurrencyResources(arr)
      this.work_centers = workCenters
    },
    // <!-- Open & Close Modal Logic  -->
    async OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      await this.fetchCombos()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.formObj.form.id = '0'
        this.formObj.form.name = ''
        this.formObj.form.code = ''
        this.formObj.form.details = ''
        this.flag = true
        this.rules = {
          work_center_id: [
            { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
          ],
          name: [
            { required: true, message: 'El campo Nombre de Almacén es obligatorio.', trigger: 'blur' },
          ],
          code: [
            { required: true, message: 'El campo Clave del Almacén es obligatorio.', trigger: 'blur' },
          ],
          details: [
            { required: true, message: 'El campo Detalles del Almacén es obligatorio.', trigger: 'blur' },
          ],
          wlevels: [
            { required: true, message: 'El campo Número de Niveles es obligatorio.', trigger: 'blur' },
          ],
          wposition_rows: [
            { required: true, message: 'El campo Número de Renglones es obligatorio.', trigger: 'blur' },
          ],
          wposition_columns: [
            { required: true, message: 'El campo Número de Columnas es obligatorio.', trigger: 'blur' },
          ],
        }
      } else {
        // Update case
        this.formObj.form.id = data.id
        this.formObj.form.name = data.name
        this.formObj.form.code = data.code
        this.formObj.form.details = data.details
        this.formObj.form.work_center_id = parseInt(data.work_center_id)
        this.flag = false
        this.rules = {
          work_center_id: [
            { required: true, message: 'El campo Centro de Trabajo es obligatorio.', trigger: 'blur' },
          ],
          name: [
            { required: true, message: 'El campo Nombre de Almacén es obligatorio.', trigger: 'blur' },
          ],
          code: [
            { required: true, message: 'El campo Clave del Almacén es obligatorio.', trigger: 'blur' },
          ],
          details: [
            { required: true, message: 'El campo Detalles del Almacén es obligatorio.', trigger: 'blur' },
          ],
        }
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.formObj.form.id = '0'
      this.formObj.form.name = ''
      this.formObj.form.code = ''
      this.formObj.form.details = ''
      // this.formObj.form.work_center_id = undefined
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    async onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // let action = 'post'
          let array = []
          if (this.formObj.form.id === '0') {
            array = this.formObj
            delete array.form.id
            this.PostResourcev2(array, async () => {
              await this.FetchTable()
              this.load = false
              this.CloseModal()
            })
          } else {
            array = this.formObj
            delete array.form.wlevels
            delete array.form.wposition_rows
            delete array.form.wposition_columns
            this.PutResourcev2(array, async () => {
              await this.FetchTable()
              this.load = false
              this.CloseModal()
            })
            // Update Object
          }
        } else {
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          me.load = true
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: me.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.FetchTable()
              me.load = false
              me.CloseModal()
            })
            .catch((errors) => {
              console.log(errors)
              me.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs.ruleForm.resetFields()
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>

<style scoped>

</style>
