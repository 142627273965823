<template>
  <div>
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="refreshResults" style="margin-top:20px"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="myCustomColumns" slot-scope="text, record">
        <slot name="details" :record="record"/>
      </template>

      <template slot="customInitialDate" slot-scope="text, record">
        <slot name="initialDate" :record="record"/>
      </template>
      <template slot="customFinalDate" slot-scope="text, record">
        <slot name="finalDate" :record="record"/>
      </template>

      <!-- Columnas Personalizadas -->
      <a slot="action" slot-scope="data">
        <responsive-button v-if="typeTable === 'catalogs'"
                           variant="secondary"
          pill size="sm" responsive="md"
          text="Editar" icon="pencil-square"
          @ClickEvent="showModal('edit',data)" style="margin-bottom:5px"
        />
        <responsive-button v-if="typeTable === 'catalogs'"
                           variant="warning"
          pill size="sm" responsive="md"
          text="Editar niveles" icon="pencil-square"
          @ClickEvent="showModal('edit',data)" :to="{name: 'warehousesCatalogs1', params: {id: data.id, name: data.name}}"
        />
        <div v-else-if="typeTable === 'serviceTypes'">
          <responsive-button variant="secondary"
                             pill size="sm" responsive="md"
                             text="Editar" icon="pencil-square"
                             @ClickEvent="showModal('edit',data)"
          />
<!--          <span v-show="data.enabled" class="text-danger font-weight-bold">-->
<!--            No está permitida la edición para este registro-->
<!--          </span>-->
        </div>
        <responsive-button v-else-if="typeTable === 'select'"
                           variant="info"
          pill size="sm" responsive="md"
          text="Seleccionar" icon="pencil-square"
          @ClickEvent="Selected(data)"
        />
        <responsive-button v-else variant="info"
          pill size="sm" responsive="md"
          text="Seleccionar Unidad" icon="pencil-square"
          @ClickEvent="showModal('edit',data)"
        />
      </a>
      <div slot="isActive" slot-scope="row">
        <b-icon icon="check-circle-fill" variant="success" v-if="row.is_active==true"/>
        <b-icon icon="x-circle-fill" variant="danger" v-else/>
      </div>
      <template slot="clientContracts" slot-scope="text, record">
        <slot name="client_contracts" :record="record"/>
      </template>
      <template slot="sapMaterials" slot-scope="text, record">
        <slot name="sap_materials" :record="record"/>
      </template>
      <template slot="materials" slot-scope="text, record">
        <slot name="materials" :record="record"/>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  name: 'DetailCatalogTable',
  props: {
    typeTable: {
      default: 'catalogs',
      type: String,
    },
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    Selected(data = {}) {
      this.$emit('Select', data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
